import * as React from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import '../css/Footer.css';
import '../css/App.css';

const ReactMarkdown = require('react-markdown/with-html');

interface Props {

}

interface State {
 markdown: string,
}

export default class Footer extends React.Component<Props, State> {
  constructor(props :Props) {
    super(props);
    this.state = {
      markdown: '',
    };
  }
  
  async componentDidMount() {
    await this.updateMd(null);
  }

  async componentDidUpdate(prevProps :any) {
    await this.updateMd(prevProps);
  }

  async updateMd(prevProps :any) {
    if (!_.isEqual(this.props, prevProps)) { 
      let markdownUrl :any = `${window.location.origin}/text/footer_fi.md`;
      let thisMarkdown: any = await axios.get(markdownUrl);
      this.setState({ markdown: thisMarkdown.data });
    }
  }

  render() {
    return (
      <div className="Footer-wrapper">

        <div className="Footer-tkrt">
        </div>

        <div className="blue App-section">
          <div className="App-edge">  
          </div>
          <div className="App-center">
  
              <ReactMarkdown
                source={this.state.markdown}
                escapeHtml={false}
              />

          </div>
          <div className="App-edge">  
          </div>
        </div>
      
      </div>
    );
  }
}