import * as React from 'react';

interface Props {
  shown: number,
}

interface State {
  images: string[],
}

export default class ImageShow extends React.Component<Props, State> {
  constructor(props :Props) {
    super(props);
    this.state = {
      images: [
        "/images/etusivu_header.jpg",
        "/images/tkrt_header.jpg",
        "/images/uskosta_header.jpg",
      ],
    };
  }

  render() {
    return (
        <div className="App-header">
            <img
              src={this.state.images[this.props.shown]}
              alt="Header" id="App-header-image"
              />
        </div>
    );
  }
}