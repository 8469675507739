import * as React from 'react';
import { Link } from 'react-router-dom';
import '../css/Topbar.css';
import '../css/App.css';

interface Props {
  highlighted: number,
  languageI: number,
}

interface State {
  contents: string[][],
  enContents: string[][],
  languages: string[][],
}

export default class Topbar extends React.Component<Props, State> {
  constructor(props :Props) {
    super(props);
    this.state = {
      contents: [
        ["ETUSIVU", "/"],
        ["TKRT", "/tkrt"],
        ["USKOSTA", "/uskosta"],
      ],
      enContents: [
        ["FRONTPAGE", "/en"],
      ],
      languages: [
        ["Suomeksi", "/"],
        ["English", "/en"],
      ],
    };
  }

  createLink(highlightIndex: number) {
    let links: any = [];
    let i: number = 0;

    let contents: string[][] = [];
    switch (this.props.languageI) {
      case 0:
          contents = this.state.contents;
          break;
      case 1:
          contents = this.state.enContents;
          break;
    }

    contents.forEach((link) => {
      if (i === highlightIndex) {
        links.push(
          <div
            className="Topbar-link"
            id="Topbar-highlight"
            key={i}
          >
            {link[0]}
          </div>
        )
      }
      else {
        links.push(
          <Link to={link[1]} key={i}>
            <div className="Topbar-link">
              {link[0]}
            </div>
          </Link>
        )
      }
      i++;
    });
    return links;
  }

  createLanguage(revmoveIndex: number) {
    let links: any = [];
    let i: number = 0;
    this.state.languages.forEach((link) => {
      if (i !== revmoveIndex) {
        links.push(
          <Link to={link[1]} key={i}>
            <div className="Topbar-link">
              {link[0]}
            </div>
          </Link>
        )
      }
      i++;
    });
    return links;
  }

  render() {
    return (
      <div className="blue App-section">
          <div className="App-edge">
          </div>
          <div className="App-center">

            <div className="Topbar-container">
              <div className="Topbar-logo">
                <img src="/images/tkrt_logo.png" alt="Logo"/>
              </div>
              <div className="Topbar-links">
                {this.createLink(this.props.highlighted)}
              </div>
              <div className="Topbar-language">
                {this.createLanguage(this.props.languageI)}
              </div>
          </div>
          
        </div>
        <div className="App-edge">  
        </div>
      </div>
    );
  }
}
