import React from 'react';
import './css/App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Frontpage from './pages/Frontpage';
import Always from './pages/Always';
import TKrT from './pages/TKrT';

import Footer from './components/Footer';

// const { SubMenu } = Menu;
// const { Header, Content, Sider } = Layout;

interface Props {

}

interface State {
  loaded: boolean;
}


export default class App extends React.Component<Props, State> {
  constructor(props :Props) {
    super(props);
    this.state = {
        loaded: false,
    };
  }

  render() {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
    return (
      <BrowserRouter>

        <div className="App">
          
          <Switch>
            <Route path="/" exact>
              <Frontpage languageI={0}/>
            </Route>
            <Route path="/uskosta">
              <Always languageI={0}/>
            </Route>
            <Route path="/tkrt">
              <TKrT languageI={0}/>
            </Route>
            <Route path="/en" exact>
              <Frontpage languageI={1}/>
            </Route>
            <Redirect from="/*" to="/" />
          </Switch>

          <Footer />

        </div>

      </BrowserRouter> 
    );
  }
}


/*
  PageFrontpage = () => <Frontpage languageI={this.state.languageI}/>;
  PageAlways = () => <Always languageI={this.state.languageI}/>;
  PageTKrT = () => <TKrT languageI={this.state.languageI}/>;
  PageFaces = () => <Faces languageI={this.state.languageI}/>;

          <Switch>
            <Route path="/" exact component={this.PageFrontpage} />
            <Route path="/aina" component={this.PageAlways} />
            <Route path="/tkrt" component={this.PageTKrT} />
            <Route path="/naamat" component={this.PageFaces} />
          </Switch>
*/

// 15ccfe sininen
// eeeeee harmaa
