import * as React from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import '../css/App.css';
import '../css/Markdown.css';

import Topbar from '../components/Topbar';
import ImageShow from '../components/ImageShow';

// @ts-ignore
const ReactMarkdown = require('react-markdown/with-html');

interface Props {
  languageI: number, // 0 = Finnish, 1 = English
}

interface State {
  pageI: number,
  markdown: string,
}

export default class Always extends React.Component<Props, State> {
  constructor(props :Props) {
  super(props);
    this.state = {
      pageI: 2,
      markdown: '',
    };
  }

  async componentDidMount() {
    await this.updateMd(null);
  }

  async componentDidUpdate(prevProps :any) {
    await this.updateMd(prevProps);
  }

  async updateMd(prevProps :any) {
    if (!_.isEqual(this.props, prevProps)) { 
      let markdownUrl: any = "";
      switch (this.props.languageI) {
        case 0:
          markdownUrl = `${window.location.origin}/text/always_fi.md`;
          break;
        case 1:
          markdownUrl = `${window.location.origin}/text/always_en.md`;
      }
      let thisMarkdown: any = await axios.get(markdownUrl);
      this.setState({ markdown: thisMarkdown.data });
    }
  }
  
  render() {
    return (
      <div className="App">

<Topbar highlighted={this.state.pageI}
          languageI={this.props.languageI}/>

        <ImageShow shown={this.state.pageI}/>


        <div className="App-section">
          <div className="App-edge">
          </div>
          <div className="App-center">


            <div className="App-content" id="container">
              <ReactMarkdown
                source={this.state.markdown}
                escapeHtml={false}
              />
            </div>


          </div>
          <div className="App-edge">  
          </div>
        </div>
        
      </div>
    );
  }
}
